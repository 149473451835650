import React, { lazy, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error404 from "../components/Error404";
import Layout from "../layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Suspense } from "react";
import Loader from "../components/Loader";
// import { auth } from "../config/fire";
// import { onAuthStateChanged } from "firebase/auth";
// import { onAuthStateChanged } from "firebase/auth";
// import { auth } from "../config/fire";

const LazyPlayerDetails = lazy(() => import("../pages/player/PlayerDetails"));
const LazyLanding = lazy(() => import("../pages/Landing"));
// const LazySportingSignin = lazy(() => import("../pages/auth/SportingSignin"));
// const LazyViewMembers = lazy(() => import("../pages/viewmembers/ViewMembers"));
// const LazyTest = lazy(() => import("../components/Test"));
const LazyMembershipForm = lazy(() => import("../pages/auth/MembershipForm"));
const LazyMatches = lazy(() => import("../pages/matches/Matches"));
const LazyNews = lazy(() => import("../pages/news/News"));
const LazyPlayer = lazy(() => import("../pages/player/Player"));
const LazyNewsDetails = lazy(() => import("../pages/news/NewsDetails"));
const LazyClubInformation = lazy(() => import("../pages/clubinformation"));
const LazyResultReview = lazy(() => import("../pages/matches/ResultReview"));
// const LazyTicket = lazy(() => import("../pages/tickets/Tickets"));
// const LazyHomeTicket = lazy(() => import("../pages/tickets/HomeTickets"));
const LazyClubHistory = lazy(() => import("../pages/ClubHistory"));
// const LazyFoundingMembers = lazy(() =>
// 	import("../pages/clubinformation/FoundingMembers")
// );
const LazyCareers = lazy(() =>
  import("../pages/clubinformation/careers/Careers")
);
const LazyJobDescription = lazy(() =>
  import("../pages/clubinformation/careers/JobDescription")
);
const LazyShopHome = lazy(() => import("../pages/shop/ShopHome"));
const LazyMembership = lazy(() => import("../pages/shop/Membership"));
const LazyWhoswho = lazy(() => import("../pages/clubinformation/who/WhosWho"));
const LazyClubPartners = lazy(() =>
  import("../pages/clubinformation/partner/ClubPartners")
);
const LazyPartnerInfo = lazy(() =>
  import("../pages/clubinformation/partner/PartnerInfo")
);
// const LazyReset = lazy(() => import("../pages/auth/Reset"));
const LazyShopList = lazy(() => import("../pages/shop/ShopList"));
const LazyShopPromotion = lazy(() => import("../pages/shop/ShopPromotion"));
// const LazyEmailSent = lazy(() => import("../components/EmailSent"));
const LazySportingTv = lazy(() => import("../pages/sportingtv/SportingTv.jsx"));
const LazyVideoDisplay = lazy(() => import("../pages/sportingtv/VideoDisplay"));
// const LazyProfile = lazy(() => import("../pages/profile/Profile"));
// const LazyJobApply = lazy(() =>
//   import("../pages/clubinformation/careers/JobApply")
// );
const LazyTrophy = lazy(() => import("../pages/clubinformation/trophy/Trophy"));
const LazySearchPage = lazy(() => import("../pages/SearchPage"));
const Routers = () => {
  const [error, setError] = useState(false);
  // const [loggedin, setLoggedin] = useState(false);

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       setLoggedin(true);
  //     } else {
  //       setLoggedin(false);
  //     }
  //   });
  // }, []);
  return (
    <>
      <BrowserRouter>
        <ToastContainer autoClose={5000} position="top-center" limit={5} />
        <Routes>
          <Route path="/" element={<Layout error={error} />}>
            <Route
              index
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyLanding />
                </Suspense>
              }
            />
            {/* <Route
              path="sporting-signin"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazySportingSignin loggedin={loggedin} />
                </Suspense>
              }
            />
            <Route
              path="view-members"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyViewMembers loggedin={loggedin} />
                </Suspense>
              }
            />
            <Route
              path="test"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyTest />
                </Suspense>
              }
            /> */}
            <Route
              path="search/:searchData"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazySearchPage />
                </Suspense>
              }
            />
            <Route
              path="membership/register"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyMembershipForm />
                </Suspense>
              }
            />
            {/* <Route
							path="signup"
							element={
								<Suspense
									fallback={
										<div className="loader">
											<Loader />
										</div>
									}
								>
									<LazySignup />
								</Suspense>
							}
						/> */}
            {/* <Route
							path="reset-password"
							element={
								<Suspense
									fallback={
										<div className="loader">
											<Loader />
										</div>
									}
								>
									<LazyReset />
								</Suspense>
							}
						/> */}
            {/* <Route
							path="profile"
							element={
								<Suspense
									fallback={
										<div className="loader">
											<Loader />
										</div>
									}
								>
									<LazyProfile />
								</Suspense>
							}
						/> */}
            <Route
              path="news"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyNews />
                </Suspense>
              }
            />
            <Route
              path="news/:headline"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyNewsDetails />
                </Suspense>
              }
            />
            <Route
              path="players"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyPlayer />
                </Suspense>
              }
            />
            <Route
              path="players/:name"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyPlayerDetails />
                </Suspense>
              }
            />
            <Route
              path="club-info"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyClubInformation />
                </Suspense>
              }
            />
            <Route
              path="club-info/clubhistory"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyClubHistory />
                </Suspense>
              }
            />
            {/* <Route
							path="club-info/foundingmembers"
							element={
								<Suspense
									fallback={
										<div className="loader">
											<Loader />
										</div>
									}
								>
									<LazyFoundingMembers />
								</Suspense>
							}
						/> */}
            <Route
              path="club-info/trophy"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyTrophy />
                </Suspense>
              }
            />
            <Route
              path="club-info/whoswho"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyWhoswho />
                </Suspense>
              }
            />
            <Route
              path="club-info/partners"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyClubPartners />
                </Suspense>
              }
            />
            <Route
              path="club-info/partners/:name/:partnerID"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyPartnerInfo />
                </Suspense>
              }
            />
            <Route
              path="club-info/careers"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyCareers />
                </Suspense>
              }
            />
            <Route
              path="club-info/careers/:name/:jobID"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyJobDescription />
                </Suspense>
              }
            />
            {/* <Route
              path="club-info/careers/apply/:name/:jobID"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyJobApply />
                </Suspense>
              }
            /> */}
            <Route
              path="matches"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyMatches />
                </Suspense>
              }
            />
            <Route
              path="matches&:table&:tableID"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyMatches />
                </Suspense>
              }
            />
            <Route
              path="matches/:matchID"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyResultReview />
                </Suspense>
              }
            />
            {/* <Route
							path="tickets"
							element={
								<Suspense
									fallback={
										<div className="loader">
											<Loader />
										</div>
									}
								>
									<LazyTicket />
								</Suspense>
							}
						/>
						<Route
							path="tickets/hometickets"
							element={
								<Suspense
									fallback={
										<div className="loader">
											<Loader />
										</div>
									}
								>
									<LazyHomeTicket />
								</Suspense>
							}
						/> */}
            <Route
              path="/membership"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyMembership />
                </Suspense>
              }
            />
            <Route
              path="shop"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyShopHome />
                </Suspense>
              }
            />
            <Route
              path="shop/:kit"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyShopList />
                </Suspense>
              }
            />
            <Route
              path="shop/list/:promoID"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazyShopPromotion />
                </Suspense>
              }
            />
            <Route
              path="sportingtv"
              element={
                <Suspense
                  fallback={
                    <div className="loader">
                      <Loader />
                    </div>
                  }
                >
                  <LazySportingTv />
                </Suspense>
              }
            />
            {/* <Route
							path="email-verify"
							element={
								<Suspense
									fallback={
										<div className="loader">
											<Loader />
										</div>
									}
								>
									<LazyEmailSent />
								</Suspense>
							}
						/> */}
            <Route path="*" element={<Error404 setError={setError} />} />
          </Route>
          <Route
            path="/sportingtv/:videoID"
            element={
              <Suspense
                fallback={
                  <div className="loader">
                    <Loader />
                  </div>
                }
              >
                <LazyVideoDisplay />
              </Suspense>
            }
          />
          <Route
            path="/sportingtv/:latest/:videoID"
            element={
              <Suspense
                fallback={
                  <div className="loader">
                    <Loader />
                  </div>
                }
              >
                <LazyVideoDisplay />
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
